import * as React from "react"
import styled from "styled-components"
import Layout from "../../../components/layout"
import Projects from "../../../components/pp_projects"
import Footer from '../../../components/footer';
import Wrapper from '../../../components/wrap';

import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import InnerImageZoom from 'react-inner-image-zoom';

import SubscriptionScheme from "../../../images/pp/pp_ios_add_subscription.png"
import ProtoV1 from "../../../images/pp/empty_activity.gif"
import ProtoV2 from "../../../images/pp/loading.gif"
import ProtoV3 from "../../../images/pp/no_connection.gif"
import screen1 from "../../../images/pp/branded_ios.png"
import screen2 from "../../../images/pp/intro.png"
import screen3 from "../../../images/pp/notifications.png"
import screen4 from "../../../images/pp/permissions.png"
import screen5 from "../../../images/pp/signin.png"
import screen6 from "../../../images/pp/rate_types.png"
import screen7 from "../../../images/pp/contact_info.png"
import screen8 from "../../../images/pp/edit_payment.png"
import screen9 from "../../../images/pp/ios_map.png"
import iOSflow from "../../../images/pp/pp_ios_flow.png"
import iOSflow_sm from "../../../images/pp/pp_ios_flow_sm.png"
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';

const IndexPage = () => {
  return (
    <Layout navtype="dark" title="Premium Parking">
      <Header>
        <AnimationOnScroll className="col col-first" animateIn="animate__fadeInLeft" animateOnce="true">
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../../images/pp/head/ios/ui_icons.png"
            alt="Icons"
          />
        </AnimationOnScroll>
        <div className="col col-md">
          <AnimationOnScroll animateIn="animate__fadeInDown" animateOnce="true">
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../../images/pp/head/ios/ui_c1_r1.png"
              alt="Icons"
            />
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce="true">
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../../images/pp/head/ios/ui_c1_r2.png"
              alt="Icons"
            />
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeInUp" className="animate__delay-1s" animateOnce="true">
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../../images/pp/head/ios/ui_c1_r3.png"
              alt="Icons"
            />
          </AnimationOnScroll>
        </div>
        <div className="col col-spaced">
          <AnimationOnScroll animateIn="animate__fadeInDown" animateOnce="true">
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../../images/pp/head/ios/ui_c2_r1.png"
              alt="Icons"
            />
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce="true">
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../../images/pp/head/ios/ui_c2_r2.png"
              alt="Icons"
            />
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce="true">
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../../images/pp/head/ios/ui_c2_r3.png"
              alt="Icons"
            />
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeInUp" className="animate__delay-1s" animateOnce="true">
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../../images/pp/head/ios/ui_c2_r4.png"
              alt="Icons"
            />
          </AnimationOnScroll>
        </div>
        <div className="col col-last">
          <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce="true">
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../../images/pp/head/ios/ui_c3_r1.png"
              alt="Icons"
            />
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce="true">
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../../images/pp/head/ios/ui_c3_r2.png"
              alt="Icons"
            />
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce="true">
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../../images/pp/head/ios/ui_c3_r3.png"
              alt="Icons"
            />
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce="true">
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../../images/pp/head/ios/ui_c3_r4.png"
              alt="Icons"
            />
          </AnimationOnScroll>
        </div>
      </Header>
      <HeaderTitle>
        <Wrapper className="no-gaps">
          {/* <h2>Redesign <a href="https://apps.apple.com/ru/app/premium-parking/id725224274?_branch_match_id=993589916577037368&_branch_referrer=H4sIAAAAAAAAA8soKSkottLXLyhKzc0szdUtSCzKzsxL10ssKNDLyczL1s9KMTAxyw4J9nUBABMzepcrAAAA&l=en" target="_blank" rel="noreferrer">iOS</a> and <a href="https://play.google.com/store/apps/details?id=io.apptitude.premiumparking&_branch_match_id=993589916577037368&_branch_referrer=H4sIAAAAAAAAA8soKSkottLXLyhKzc0szdUtSCzKzsxL10ssKNDLyczL1rc0yXNxcrcI9nUBAJi7vf4rAAAA" target="_blank" rel="noreferrer">Android</a> Apps</h2> */}
          <h1>
            <small>Premium Parking</small>
            Polishing the parking purchase funnel in mobile apps</h1>
          <ul className="types">
            <li>
              <small>Timeframe</small>
              <br />
              Feb 2018-Jun 2019
            </li>
            <li>
              <small>Role</small>
              <br />
              Lead Product Designer
            </li>
            <li>
              <small>Responsibilities</small>
              <br />
              Research, UX design, Usability testing, Data Analysis
            </li>
            <li className="full">
              <small>Team</small>
              <br />
              Director of Product Experience, CPO, Lead Product Designer (me), Development Team Lead, 2 Back-end Engineers, Project Manager, 2 iOS Developers, 2 Android Developers
            </li>
          </ul>
          {/* <br />
          <p>Rethink the checkout flow and whole architecture to support growth</p> */}
        </Wrapper>
      </HeaderTitle>
      <AnimationOnScroll animateIn="animate__wrapInUp" animateOnce="true">
      <Wrapper className="first">
        <p>
          <a href="https://premiumparking.com" target="_blank" rel="noreferrer">Premium Parking</a> is digital parking solution in the US, with over 500 employees and $105.7M in annual revenue.
        </p>
      <strong className="sub-title">My role and the team</strong>
      <h3>Eating your own dog food</h3>
      <p>
        After the successful launch of the <Link to="/projects/premium-parking/pam">Business Account</Link>, I was involved in the redesigning of mobile apps. I worked as a product designer, embedded on the mobile development team with a handful of engineers, some PM folks, and C-level executives. C-Level executives are loyal app users with a long history.</p>
      <p>I have participated in all workshops and user testing sessions. I also took part in summarizing insights and crafting high fidelity mockups to help implement potential solutions through design ideation, iteration, and prototyping.</p>

      <strong className="sub-title">Challenges and goals</strong>
      <h3>Our clients success is our success</h3>
      <p>
        As a team, our mandate was simple: do whatever we could as a company to make it easier to pay for parking and not be booted. One more thing from Company President: "We also would like to lighten up the color scheme and overall feel."
        <br/>
        <AnchorLink to="#results">View Results</AnchorLink>
      </p>

      <h3>Historical context</h3>
      <div className="history history-mobile">
        <StaticImage
          placeholder="blurred"
          formats={["AUTO", "WEBP", "AVIF"]}
          src="../../../images/pp/ios_old_location.png"
          alt="Old Location"
        />
        <StaticImage
          placeholder="blurred"
          formats={["AUTO", "WEBP", "AVIF"]}
          src="../../../images/pp/ios_old_profile.png"
          alt="Old Profile"
        />
        <StaticImage
          placeholder="blurred"
          formats={["AUTO", "WEBP", "AVIF"]}
          src="../../../images/pp/ios_old_vehicles.png"
          alt="Old Vehicles"
        />
        <StaticImage
          placeholder="blurred"
          formats={["AUTO", "WEBP", "AVIF"]}
          src="../../../images/pp/ios_old.png"
          alt="Old Auth"
        />
      </div>
      <strong className="sub-title">Design Process</strong>
      <h3>Picking up the pieces</h3>
      <p>
        We looked into what currently existed and aimed to better understand what our end customers were currently doing and why.
      </p>
      <p>
        Before investigating, we realized that flurry does not track all necessary events. So, we prepared a list of necessary events to track better user behavior.
      </p>
      <p>
        I looked at many different points of data — qualitative and quantitative (flurry events) from internal sources, and the most frequent issues from support. Also, on the App Store, we found two reviews with a 1-star rating. Customer's car was stolen, another one scratched :(
      </p>
      <p>All team members installed staging apps, and we created parking in our cities. So, we started using the parking app to share our own experiences.</p>

      <strong className="sub-title">Define</strong>
      <h3>Goals of the redesign</h3>
      <ol>
        Flurry events showed us:
        <li>Search is the most popular action</li>
        <li>Most of customers with disabled push notifications</li>
        <li>Auth problem for customers who previously paid in TextPay</li>
        <li>Checkout Abandonment (money went to enforcement)</li>
        <li>Low MAU/DAU</li>
      </ol>
      <p>
        From support.
        <br/>
        Customers made mistakes with parking numbers. Signup issue for customers who previously paid in TextPay.
      </p>
      <p>
        Our expert feedback.
        <br/>
        The app had issues with feedback. The application used non-standard controls that people did not understand how to use. No convenient payment methods
      </p>
      <p>
        Other.
        <br/>
        You need to try to increase the number of positive reviews in the App Store and Google Play Market.
      </p>
      <strong>Ideate</strong>
      <p>
        After estimate-sessions with the dev team, we realized that fixing all issues in the app may take months.
        <br/>
        So we started with "low hanging fruits" that could make it easier to pay and reduce refunds. We decided to make some of the features in the old application to evaluate their effect.
      </p>
      <ol>
        <li>Add ability to pay via Apple/Android Pay</li>
        <li>Fix dead-end scenarios on the payment screen</li>
        <li>Show list of nearby parking lots</li>
        <li>Fix auth in mobile apps for textpay customers</li>
      </ol>
      <p>
        The biggest change in rates grid.
        <br/>
        Almost each location has rates for 1, 2, 4, 8, 12, 24 hours. The main idea was to allow customers to choose any parking duration.
        Rate calculation logic: If the client has chosen 6 hours, then they pay for 8, because we didn't have a 6-hour rate. It might increase revenue. We decided to try this.
      </p>
      <p>To increase the app's rate, we decided to split asking for reviews in two steps. At first, the app asked for a rating, and then, if the rating was above 4, we sent it to the app store. In other cases, we collected reviews in "delighted" for further improvements. In some cases, support contacted with customers to help.</p>
      <h3>Designing the solution</h3>
      <p>
        I've replaced all custom (non-obvious) controls with native as much as it was possible for each platform. I redesigned the entire checkout flow, the location page, and added onboarding. To maintain consistency and provide an efficient handover, I extracted reusable components and their states into a library. For example: cards, list items, controls, button, location numbers, and others. It started with Sketch, later transferred to Figma. Every component can be rearranged and combined with others while maintaining design consistency and recognizable UI patterns for the user. Subscriptions were ready on the web-app and back-end side, so we could plan it for upcoming releases. Subscriptions required more information about Parker, so had a different checkout flow.
      </p>
    </Wrapper>
    <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true">
      <div className="wide-scheme">
        <InnerImageZoom src={SubscriptionScheme} zoomSrc={SubscriptionScheme} />
      </div>
    </AnimationOnScroll>
    <div className="screens-grid">
      <div className="col">
        <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce="true">
          <img src={screen2} alt="Splash" className='mobile-screen' />
        </AnimationOnScroll>
      </div>
      <div className="col">
        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true">
          <img src={screen3} alt="Notifications" className='mobile-screen' />
        </AnimationOnScroll>
      </div>
      <div className="col">
        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true">
          <img src={screen4} alt="Location Services" className='mobile-screen' />
        </AnimationOnScroll>
      </div>
      <div className="col">
        <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce="true">
          <img src={screen5} alt="Auth" className='mobile-screen' />
        </AnimationOnScroll>
      </div>
    </div>
    <div className="screens-grid">
      <div className="col">
        <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce="true">
          <img src={screen1} alt="Offline" className='mobile-screen' />
        </AnimationOnScroll>
      </div>
      <div className="col">
        <AnimationOnScroll animateIn="animate__slideInUp" animateOnce="true">
          <img src={ProtoV3} alt="Offline" className='mobile-screen' />
        </AnimationOnScroll>
      </div>
      <div className="col">
        <AnimationOnScroll animateIn="animate__slideInUp" animateOnce="true">
          <img src={ProtoV1} alt="No activity" className='mobile-screen' />
        </AnimationOnScroll>
      </div>
      <div className="col">
        <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce="true">
          <img src={ProtoV2} alt="Shimmers" className='mobile-screen' />
        </AnimationOnScroll>
      </div>
    </div>
    <Wrapper>
      <h3>Validate the prototypes</h3>
      <p>Since the changes were quite significant, it was impossible to do without usability tests. I built a prototype in FramerJS with price calculation logic.</p>
      <p>We feared that the new checkout first experience would cause issues because it was more friction and a fundamentally different flow. To our surprise, not a single participant noticed the different sequencing of the flow or had trouble with it. </p>
      <ul>
        Things that tested and worked:
        <li>Navigation became clearer. No lost context</li>
        <li>The new checkout flow fixed cases with no license plate number</li>
        <li>No misunderstandings with the list of durations.</li>
        <li>Location detection saves time</li>
      </ul>
      <ul>
        Things to improve:
        <li>Onboarding should be shorter</li>
        <li>Handle case if no network connection (underground parking)</li>
      </ul>
    </Wrapper>
    <div className="screens-grid">
      <div className="col">
        <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce="true">
          <img src={screen6} alt="Offline" className='mobile-screen' />
        </AnimationOnScroll>
      </div>
      <div className="col">
        <AnimationOnScroll animateIn="animate__slideInUp" animateOnce="true">
          <img src={screen7} alt="Offline" className='mobile-screen' />
        </AnimationOnScroll>
      </div>
      <div className="col">
        <AnimationOnScroll animateIn="animate__slideInUp" animateOnce="true">
          <img src={screen8} alt="No activity" className='mobile-screen' />
        </AnimationOnScroll>
      </div>
      <div className="col">
        <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce="true">
          <img src={screen9} alt="Shimmers" className='mobile-screen' />
        </AnimationOnScroll>
      </div>
    </div>
    <Wrapper>
      <h3>Refine and iterate</h3>
      <p>We launched a rates experiment only at one location (the growth in revenue was noticeable), then it was enabled at all locations. But then we realized that it does not work well everywhere. So, we turned the updated rates grid into a location setting that can be turned on/off.</p>
    </Wrapper>
    <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce="true">
      <div className="wide-scheme">
        <InnerImageZoom src={iOSflow_sm} zoomSrc={iOSflow} />
      </div>
    </AnimationOnScroll>
    <Wrapper>
      <h3 id="results">Results</h3>
      <p>A well-organized and flexible application with a design system allowed us to quickly design and connect new integrations and functionality in the future (travel, insurance, data providers).</p>
      <ul>
        <li>Conversion to purchase increased by 5.68%</li>
        <li>DAU increased by 2 times and still growing</li>
        <li>Review Rate went up from 2.3 to 4.5</li>
      </ul>

    </Wrapper>
    <Projects project="mobile" />
    </AnimationOnScroll>
  <Footer />
</Layout>
  )
}

const Header = styled.div`
  background: var(--color-text);
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start; 
  justify-content: right;
  padding: 30px 0 0 20px;
  overflow: hidden;
  column-gap: 30px;
  margin-bottom: 0;

  .col {
    text-align: center;
  }

  .col-first {
    text-align: right;
  }

  .col-last {
    text-align: left;
    margin-right: -30px;
  }

  .col .animate__animated {
    margin-bottom: 1rem;
  }

  @media (max-width: 1024px) {
    .col-first {
      display: none;
    }
  }

  @media (max-width: 480px) {
    column-gap: 20px;

    .col-md {
      display: none;
    }

    .col-last .animate__animated:last-child {
      display: none;
    }
  }
`

const HeaderTitle = styled.div`
  background: var(--color-text);
  color: #fff;
  margin-top: -130px;

  p {
    font-size: 1.3rem;
    margin: 0;
    line-height: normal;
  }

  h2 {
    margin-bottom: 5px;
  }

  a[target="_blank"] {
    .dark &,
    .light & {
      color: #D9272D;
      text-decoration: none;
    }

    &:after {
      font-size: 80%;
      top: 0;
    }

    &:hover {
      box-shadow: 0 1px 0 0 #D9272D;
    }
  }
  
  @media (max-width: 1440px) {
    margin-top: -100px;
  }

  @media (max-width: 1024px) {
    margin-top: -50px;
  }

  @media (max-width: 480px) {
    margin-top: -10px;
  }

  .light & {
    margin-bottom: 70px;
  }
`

export default IndexPage
